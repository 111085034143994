<template>
  <div class="ykc-electrovalence-range">
    <div class="range-selector" ref="RangeSelector">
      <el-popover
        ref="Popover"
        width="204"
        popper-class="ykc-electrovalence-range-popper"
        trigger="manual"
        :visible-arrow="false"
        v-model="visible">
        <div class="range">
          <el-input readonly size="small" :value="timeRange[0]"></el-input>
          <span class="separator"></span>
          <el-input readonly size="small" :value="timeRange[1]"></el-input>
        </div>
        <div class="tip">温馨提示：再次点击确认时段</div>
        <div class="footer">
          <el-button size="small" class="cancel" @click="handleCancel">取消</el-button>
          <el-button size="small" class="confirm" type="primary" @click="handleConfirm">
            确认
          </el-button>
        </div>
        <!-- 弹出层四个角的特定样式 -->
        <div class="corner">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <!-- 自定义箭头 -->
        <div class="custom-arrow outer" :style="arrowStyle"></div>
        <div class="custom-arrow inner" :style="arrowStyle"></div>
        <!-- 相对参考点，动态更新此元素位置即可更新弹出层位置 -->
        <span slot="reference" class="center" :style="popoverPosition"></span>
      </el-popover>
      <div class="row row-1">
        <div
          v-for="(direction, index) in lines"
          :key="`line-${direction}`"
          class="row-line"
          :class="[`row-line-${direction}`]">
          <div>
            <span>{{ splitLineTime[0][index] }}</span>
          </div>
        </div>
        <div
          v-for="item in totalItems.slice(0, totalItems.length / 2)"
          :key="`item-${item.index}`"
          class="range-item"
          :style="calculateItemStyle(item)"
          @click="handleItemClick(item)"
          @mousemove="handleItemMousemove(item)"
          @contextmenu.prevent="handleItemContextmenu(item)"></div>
      </div>
      <div class="row row-2">
        <div
          v-for="(direction, index) in lines"
          :key="`line-${direction}`"
          class="row-line"
          :class="[`row-line-${direction}`]">
          <div>
            <span>{{ splitLineTime[1][index] }}</span>
          </div>
        </div>
        <div
          v-for="item in totalItems.slice(totalItems.length / 2)"
          :key="`item-${item.index}`"
          class="range-item"
          :style="calculateItemStyle(item)"
          @click="handleItemClick(item)"
          @mousemove="handleItemMousemove(item)"
          @contextmenu.prevent="handleItemContextmenu(item)"></div>
      </div>
    </div>
    <div>
      <slot name="table" :data="value"></slot>
      <span class="clear-btn" @click="handleItemContextmenu">清除</span>
    </div>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'YkcElectrovalenceRange',
    props: {
      value: {
        type: Array,
        required: true,
      },
      maxCount: {
        type: Number,
        default: 0,
      },
      poperShowAt: {
        type: String,
        default: 'start',
        validator: val => ['start', 'end'].includes(val),
      },
      /** 表格时间分隔符 */
      tableTimeSeparator: {
        type: String,
        default: '~',
      },
      /** 无状态时的背景色 */
      emptyBackground: {
        type: String,
        default: 'linear-gradient(180deg, #A5B2C4 0%, #6C7A92 100%)',
      },
      groups: {
        type: Array,
        required: true,
        validator(val) {
          if (val.length === 0) {
            return false;
          }
          // 必须要有的属性
          const requiredKeys = ['value', 'background', 'label'];
          return val.every(item => requiredKeys.every(key => key in item));
        },
      },
      /** 选中的状态 */
      selectedStatus: {
        type: String,
        required: true,
      },
      /** 小时是否补齐成2位 */
      hourPad: {
        type: Boolean,
        default: true,
      },
      /** 分割线时间字符串 */
      splitLineTime: {
        type: Array,
        default: () => [
          ['0:00', '6:00', '12:00'],
          ['12:00', '18:00', '24:00'],
        ],
      },
      /** 无状态节点作为剩余时段的默认状态 */
      defaultEmptyStatus: {
        type: String,
        default: 'empty',
      },
      /** 是否可以取消 */
      clearable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        arrowPosition: 'top',
        // 每行的线条数量
        lines: ['left', 'center', 'right'],
        // 总数据
        totalItems: Array.from({ length: 48 }).map((_, index) => ({
          // 保存下标
          index,
          // 状态
          status: '',
          // 时间区间的开始时刻
          start: [Math.floor(index / 2), (index % 2) * 30],
          // 时间区间的结束时刻
          end: [Math.floor((index + 1) / 2), ((index + 1) % 2) * 30],
        })),
        // 弹出层是否可见
        visible: false,
        // 弹出层的位置，根据鼠标滑动到哪个节点计算得出
        popoverPosition: {
          marginTop: '0',
          marginLeft: '0',
        },
        // 弹出层区间值
        timeRange: [null, null],
        // 操作时间区间时，保存的起始节点
        range: {
          start: null,
          end: null,
          lockMousemove: false,
        },
        // 操作时间区间时，先保存原始数据，方便取消等情况重置数据
        snapshot: null,
        // 弹出层下拉框的值，如果selectedStatus有值，应该与selectedStatus相同
        groupValue: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.setTotalRangeColor(val);
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      // innerValue() {
      //   const result = [...this.value];
      //   const restRange = this.getRestRange();
      //   if (restRange) {
      //     result.push(restRange);
      //   }
      //   return result;
      // },
      arrowStyle() {
        if (this.arrowPosition === 'top') {
          return {
            top: '0',
            transform: 'translate(-50%, -100%)',
          };
        }
        return {
          bottom: 0,
          transform: 'translate(-50%, 100%) rotateZ(180deg)',
        };
      },
    },

    mounted() {
      // 此处没有使用clickoutside指令，是因为其控制不够细（对弹出层无法兼容）
      // 点击时间范围外面则取消选择操作
      const handler = ({ target }) => {
        if (this.visible && [this.$refs.RangeSelector].every(el => !el.contains(target))) {
          this.handleCancel();
        }
      };
      window.document.addEventListener('click', handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });
    },
    methods: {
      /** 获取剩余时段 */
      getRestRange() {
        const emptyItems = this.totalItems.filter(item => !item.status);
        if (this.value.length > 0 && emptyItems.length > 0) {
          return {
            type: this.defaultEmptyStatus,
            // 剩余时间段
            isRestRange: true,
            label: '剩余时段',
          };
        }
        return null;
      },
      /** 全部节点着色 */
      setTotalRangeColor(value) {
        // value的格式应该为
        // {
        //     type: 'xxx',
        //     start: '01:30',
        //     end: '03:00',
        // }
        // 且value每个值不相交
        /**
         * 比较字符串时间与数组时间是否相等
         * 比如："01:30" === [1, 30] 值为 true
         */
        const isEqual = (str, arr) => arr.every((val, i) => val === str.split(':').map(Number)[i]);
        const { totalItems } = this;
        value.forEach(range => {
          const { start, end, type } = range;
          const startIndex = totalItems.findIndex(item => isEqual(start, item.start));
          const endIndex = totalItems.findIndex(item => isEqual(end, item.end));
          if (startIndex > -1 && endIndex > -1) {
            const minIndex = Math.min(startIndex, endIndex);
            const maxIndex = Math.max(startIndex, endIndex);
            totalItems.slice(minIndex, maxIndex + 1).forEach(item => {
              item.status = type;
            });
          }
        });
      },
      /** 操作时间区间前，保存全部节点快照，方便取消时恢复数据 */
      saveSnapshot() {
        this.snapshot = this.totalItems.map(item => ({ ...item }));
      },
      /** 清除快照 */
      clearSnapshot() {
        this.snapshot = null;
      },
      /** 计算节点样式 */
      calculateItemStyle(item) {
        const { emptyBackground, groups } = this;
        const { status, index } = item;
        const style = {};
        if (status) {
          const group = groups.find(g => g.value === status);
          style.background = group ? group.background : emptyBackground;
        }
        if ([11, 35].includes(index)) {
          style.marginRight = '5px';
        }
        return style;
      },
      /** 计算弹出层位置 */
      calculatePosition(item) {
        const { index } = item;
        // 每个节点的宽度
        const width = 14.67;
        // 相邻节点的间距
        const gutter = 4;
        const itemWidth = width + gutter;
        // 第一行
        if (index < 24) {
          this.arrowPosition = 'bottom';
          // const marginTop = '-16px';
          const marginTop = '-240px';
          // 第一行中间点(index=11和12之间)的左侧和右侧
          const marginLeft = `${
            index < 12
              ? -(itemWidth / 2 + (11 - index) * itemWidth)
              : itemWidth / 2 + (index - 12) * itemWidth
          }px`;
          this.popoverPosition = { marginTop, marginLeft };
        } else {
          this.arrowPosition = 'top';
          // 第二行
          const marginTop = '48px';
          // 第二行中间点(index=35和36之间)的左侧和右侧
          const marginLeft = `${
            index < 36
              ? -(itemWidth / 2 + (35 - index) * itemWidth)
              : itemWidth / 2 + (index - 36) * itemWidth
          }px`;
          this.popoverPosition = { marginTop, marginLeft };
        }
        if (this.$refs.Popover && this.$refs.Popover.popperJS) {
          this.$nextTick(() => {
            // 更新弹出层位置
            this.$refs.Popover.popperJS.update();
          });
        }
      },
      /** 计算弹出层时间区间展示的值 */
      calculateRangeValue() {
        const { range, totalItems, hourPad } = this;
        const { start } = range;
        const end = range.end || start;
        if (start && end) {
          // 因为开始和结束节点时间没有排序，故此处需要先排序
          const startItemIndex = start.index;
          const endItemIndex = end.index;
          const maxIndex = Math.max(endItemIndex, startItemIndex);
          const minIndex = Math.min(endItemIndex, startItemIndex);
          this.timeRange = [
            totalItems[minIndex].start.reduce((res, val, idx) => {
              if (idx === 0) {
                res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
              } else {
                res += String(val).length > 1 ? val : `0${val}`;
              }
              return res;
            }, ''),
            totalItems[maxIndex].end.reduce((res, val, idx) => {
              if (idx === 0) {
                res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
              } else {
                res += String(val).length > 1 ? val : `0${val}`;
              }
              return res;
            }, ''),
          ];
        }
      },
      /** 更新节点状态 */
      updateItemStatus(item) {
        this.$nextTick(() => {
          item.status = this.groupValue || 'empty';
        });
      },
      /**
       * 节点点击处理
       * 没有选中开始节点，则点击的节点就是开始节点，首先进行快照操作，然后保存开始节点和结束节点信息（一个节点时，开始节点就是结束节点），并更新开始节点样式
       * 如果存在开始节点，那么再次点击时表示确认对该区间进行电价配置
       */
      handleItemClick(item) {
        const { range, saveSnapshot, updateItemStatus, maxCount } = this;
        if (maxCount > 0) {
          if (this.value.length === maxCount) {
            this.$emit('reach-max-count');
            return false;
          }
        }
        if (range.lockMousemove) {
          this.$emit('item-click-after-lock', item);
          return false;
        }
        // 未选择开始点
        if (!range.start) {
          // 保存前一次的快照，取消时恢复
          saveSnapshot();
          // 记录开始节点
          range.start = item;
          // 记录结束节点
          range.end = item;
          // 更新开始节点颜色
          updateItemStatus(item);
          if (this.poperShowAt === 'start') {
            // 打开弹出层
            this.visible = true;
          }
          // 默认选中状态
          this.groupValue = this.groups.some(g => g.value === this.selectedStatus)
            ? this.selectedStatus
            : '';
          // 更新弹出层位置
          this.calculatePosition(item);
          // 计算弹出层输入框时间区间的显示值
          this.calculateRangeValue();
        } else {
          if (this.poperShowAt === 'end') {
            // 打开弹出层
            this.visible = true;
          }
          range.end = item;
          // 高亮选中的节点
          this.setSelectedRangeColor();
          // 更新弹出层位置
          this.calculatePosition(item);
          // 计算弹出层输入框时间区间的显示值
          this.calculateRangeValue();
          // 锁定mousemove后，鼠标移动无效
          range.lockMousemove = true;
        }
        return true;
      },
      /** 鼠标移动到节点上更新选中区间 */
      handleItemMousemove(item) {
        const { range } = this;
        // 没有开始节点，即没有进行操作，鼠标移动无效
        // 有开始节点，但是是锁定状态，即通过点击确定了结束节点，鼠标再移动也无效
        if (!range.start || range.lockMousemove) {
          return false;
        }
        // 保存结束节点
        range.end = item;
        // 计算弹出层输入框时间区间的显示值
        this.calculateRangeValue();
        // 选择了开始节点，鼠标移动时弹出层位置更新
        this.calculatePosition(item);
        // 高亮选中的节点
        this.setSelectedRangeColor();
        return true;
      },
      /** 鼠标右键取消单个节点着色 */
      handleItemContextmenu(item) {
        console.log('item', item);
        const { range, clearable } = this;
        const { start, end, lockMousemove } = range;
        if (!clearable || start || end || lockMousemove) {
          return false;
        }
        // 清空高亮
        this.totalItems.forEach(i => {
          i.status = '';
        });
        // 与外部同步数据
        this.$emit('input', []);
        return true;
      },
      /** 弹出层下拉选择单价改变 */
      handleSelectChange(val) {
        // 支持.sync修饰符
        this.$emit('update:selectedStatus', val);
        this.setSelectedRangeColor();
      },
      /** 更新选中区间内的节点状态 */
      setSelectedRangeColor() {
        const { range, totalItems, snapshot, updateItemStatus } = this;
        const { start, end } = range;
        if (start && end) {
          const startItemIndex = start.index;
          const endItemIndex = end.index;
          const maxIndex = Math.max(endItemIndex, startItemIndex);
          const minIndex = Math.min(endItemIndex, startItemIndex);
          // 首尾区间中的全部节点着色
          totalItems.slice(minIndex, maxIndex + 1).forEach(item => {
            updateItemStatus(item);
          });
          // 因为可以移动到节点上，也可以从节点移开，那么移开时需要重置成原来的状态，此处是快照的作用之一
          totalItems
            .filter(({ index }) => index < minIndex || index > maxIndex)
            .forEach(item => {
              item.status = snapshot[item.index].status;
            });
        }
      },
      /** 取消操作，重置数据 */
      handleCancel() {
        this.visible = false;
        this.totalItems = this.snapshot;
        this.timeRange = [null, null];
        this.range.start = null;
        this.range.end = null;
        this.range.lockMousemove = false;
        this.clearSnapshot();
      },
      /** 确认操作，保存本地数据并同步value值 */
      handleConfirm() {
        if (!this.groupValue) {
          // 派发事件，方便外部处理
          this.$emit('empty-group-value');
          // this.$message.warning('请选择单价');
          // this.$refs.Select.focus();
          // setTimeout(() => {
          //     this.$refs.Select.blur();
          // }, 500);
          return;
        }
        const { start, end } = this.range;
        const startItemIndex = start.index;
        const endItemIndex = end.index;
        const maxIndex = Math.max(endItemIndex, startItemIndex);
        const minIndex = Math.min(endItemIndex, startItemIndex);
        this.snapshot.slice(minIndex, maxIndex + 1).forEach(item => {
          item.status = this.groupValue;
        });
        this.totalItems = [...this.snapshot];
        this.handleCancel();
        this.calculateValue();
      },
      /** 计算value的值并更新 */
      calculateValue() {
        const ranges = [];
        const { totalItems } = this;
        const { length } = totalItems;
        for (let i = 0; i < length; ) {
          const currentItem = totalItems[i];
          const currentItemStatus = currentItem.status;
          // 无状态的节点，则跳过
          if (!currentItemStatus) {
            i += 1;
            // eslint-disable-next-line no-continue
            continue;
          }
          // 有状态的节点，则从当前往后找相同状态且相邻的节点
          let j = i;
          for (; j < length; j++) {
            const innerItem = totalItems[j];
            const innerItemStatus = innerItem.status;
            // 相同且相邻则继续查找
            if (innerItemStatus === currentItemStatus) {
              // eslint-disable-next-line no-continue
              continue;
            } else {
              // 状态不同，则停止查找
              break;
            }
          }
          // j-1是最后一个状态相同的节点，保存之
          ranges.push([i, j - 1]);
          // 跳过i到j-1之间的节点
          i = j;
        }
        const { hourPad } = this;
        const value = ranges.map(range => {
          const [startIndex, endIndex] = range;
          const items = totalItems.slice(startIndex, endIndex + 1);
          const startItem = items[0];
          const endItem = items[endIndex - startIndex];
          const startTime = startItem.start.reduce((res, val, index) => {
            if (index === 0) {
              res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
            } else {
              res += String(val).length > 1 ? val : `0${val}`;
            }
            return res;
          }, '');
          const endTime = endItem.end.reduce((res, val, index) => {
            if (index === 0) {
              res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
            } else {
              res += String(val).length > 1 ? val : `0${val}`;
            }
            return res;
          }, '');
          return {
            type: startItem.status,
            start: startTime,
            end: endTime,
          };
        });
        // // 如果只选择了一种类型，且与剩余时间段类型相同，那么整天的时间都应该是剩余时间段类型
        // if (value.length === 1 && value[0].type === this.defaultEmptyStatus) {
        //     value[0].start = '00:00';
        //     value[0].end = '24:00';
        // }
        this.$emit('input', value);
      },
    },
  };
</script>

<style lang="scss">
  .ykc-electrovalence-range {
    padding: 8px 0;
    .clear-btn {
      cursor: pointer;
      margin-left: 10px;
      color: #1c6dfe;
    }
    .header {
      margin-bottom: 30px;
    }
    &-popper.el-popover {
      transition: left 0.2s linear;
      background-image: linear-gradient(180deg, #002869 0%, #001432 50%, #002869 100%);
      border-image: linear-gradient(180deg, #002869 0%, #001432 50%, #002869 100%);
      box-shadow: 2px 2px 10px 2px rgba(0, 3, 18, 0.21);
      border: 0.5px solid #0e5fff;
      box-sizing: border-box;
      // 自定义箭头
      .custom-arrow {
        position: absolute;
        left: 50%;
        // top: 0;
        // transform: translate(-50%, -100%);
        &.outer {
          width: 0px;
          height: 0px;
          border-width: 8px 12px;
          border-style: dashed dashed solid;
          border-color: transparent transparent #0e5fff;
        }
        &.inner {
          z-index: 10000;
          width: 0px;
          height: 0px;
          border-width: 6px 10px;
          border-style: dashed dashed solid;
          border-color: transparent transparent #002869;
        }
      }
      // &.el-popper[x-placement^=bottom] .popper__arrow {
      //     border-bottom-color: #002869;
      //     border-bottom: none;
      //     border-bottom-color: #0E5FFF;
      //     &::after {
      //         border-bottom-color: #002869;
      //         border-bottom-color: #0E5FFF;
      //     }
      // }
      .range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          width: 70px;
          input {
            background: rgba(14, 59, 140, 0.1);
            border: 0.5px solid #0e5fff;
            color: white;
          }
        }
        .separator {
          background: #00fff4;
          width: 8px;
          height: 1px;
        }
      }
      .el-select {
        margin-top: 10px;
        width: 100%;
        background: rgba(14, 59, 140, 0.1);
        &:hover,
        &:active,
        &:focus,
        &.is-focus {
          background: rgba(14, 59, 140, 0.1);
          input.el-input__inner {
            background: rgba(14, 59, 140, 0.1);
            border: 0.5px solid #0e5fff;
          }
        }
        input.el-input__inner {
          background: rgba(14, 59, 140, 0.1);
          border: 0.5px solid #0e5fff;
          color: #00fff4;
          &::placeholder {
            color: white;
            opacity: 0.6;
          }
          // &:focus {
          //     border-color: red;
          // }
        }
        .el-input__suffix-inner i {
          color: #00fff4;
        }
      }
      .tip {
        width: 100%;
        margin-top: 8px;
        color: #00fff4;
        line-height: 12px;
        font-size: 12px;
        // 万恶的10px字体大小
        transform-origin: top left;
        transform: scale(calc(10 / 12));
      }
      .footer {
        margin-top: 14px;
        display: flex;
        justify-content: flex-end;
        .cancel {
          background: rgba(14, 59, 140, 0.2);
          border: 0.5px solid #0e5fff;
          border-radius: 2px;
          color: white;
          &:active,
          &:hover,
          &:focus {
            background: rgba(14, 59, 140, 0.2);
            border: 0.5px solid #0e5fff;
            border-radius: 2px;
            color: white;
          }
        }
        .confirm {
          background-image: linear-gradient(90deg, #51ffdc 0%, #1c6dfe 50%, #0056ff 100%);
          border-radius: 2px;
          border: none;
          color: white;
          &:active,
          &:hover,
          &:focus {
            background-image: linear-gradient(90deg, #51ffdc 0%, #1c6dfe 50%, #0056ff 100%);
            border-radius: 2px;
            border: none;
            color: white;
          }
          &:hover {
            background-image: linear-gradient(269deg, #3e77ea 62%, #9ac6d3);
          }
        }
      }
      .corner {
        span {
          position: absolute;
          width: 5px;
          height: 3.93px;
          border: 1.5px solid #00d8ff;
        }
        :nth-child(1) {
          top: -1px;
          left: -1px;
          border-right: none;
          border-bottom: none;
        }
        :nth-child(2) {
          top: -1px;
          right: -1px;
          border-left: none;
          border-bottom: none;
        }
        :nth-child(3) {
          bottom: -1px;
          left: -1px;
          border-right: none;
          border-top: none;
        }
        :nth-child(4) {
          bottom: -1px;
          right: -1px;
          border-left: none;
          border-top: none;
        }
      }
    }
    &-select-popper.el-select-dropdown {
      border: 0.5px solid #0e5fff;
      background-image: linear-gradient(180deg, #002869 0%, #001432 50%, #002869 100%);
      box-shadow: 2px 2px 10px 2px rgba(0, 3, 18, 0.21);
      .el-scrollbar__view.el-select-dropdown__list {
        min-width: 178px;
        max-width: 178px;
        width: 178px;
      }
      &.el-popper[x-placement^='bottom'] .popper__arrow {
        border-bottom-color: #0e5fff;
        &::after {
          border-bottom-color: transparent;
        }
      }
      .el-select-dropdown__item {
        color: #ffffff;
        &.hover,
        &:hover {
          background: rgba(0, 86, 255, 0.8);
        }
        &.selected {
          color: #00fff4;
        }
      }
    }
    .range-selector {
      position: relative;
      width: 480px;
      overflow: visible;
      margin: 0 auto;
      padding: 24px 0;
    }
    .table-data {
      position: relative;
      width: 480px;
      overflow: visible;
      margin: 0 auto;
      border-radius: 2px;
      overflow: hidden;
      margin-top: 30px;
      padding: 1px;
      box-sizing: border-box;
      background-image: linear-gradient(
        to bottom,
        rgba(14, 95, 255, 1) 0%,
        rgba(61, 127, 255, 0) 25%,
        transparent 50%,
        rgba(43, 115, 255, 0) 75%,
        rgba(14, 95, 255, 1) 100%
      );
      .el-table {
        border-radius: 2px;
        background-color: #002869;
        width: 478px;
        .el-table__row {
          background-color: #002869;
          height: 40px;
          &.el-table__row:nth-child(even) {
            .el-table__cell {
              background: rgba(14, 59, 140, 0.2);
              // &:last-child {
              //     background: none;
              //     background-image: linear-gradient(
              //         to right,
              //         rgba(14,59,140,0.20) 0%,
              //         rgba(14,59,140,0.20) 100%,
              //     );
              //     // background-position: 50% 50%;
              // }
            }
          }
          .el-table__cell {
            background-color: #002869;
            border-bottom: none;
            padding: 0;
            color: white;
            border: none;
          }
        }
        .el-table__empty-block {
          background: rgba(14, 59, 140);
          min-height: 58px;
        }
        .el-table__empty-text {
          line-height: 58px;
          color: white;
        }
        &::before {
          display: none;
        }
        // 滚动条样式
        .el-table__body-wrapper {
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background: rgba(14, 59, 140, 0.08);
            border-radius: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            width: 6px;
            background-color: #0d47b9;
          }
          scrollbar-width: thin;
          scrollbar-color: #0d47b9 rgba(14, 59, 140, 0.08);
        }
      }
    }
    .center {
      position: absolute;
      width: 2px;
      height: 2px;
      top: 50%;
      left: 50%;
      background: red;
      transform: translate(-1px, -1px);
      opacity: 0;
    }
    .row {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .range-item {
        cursor: pointer;
        width: 14.67px;
        height: 34px;
        border-radius: 4px;
        display: inline-block;
        margin-right: 4px;
        background: rgba(14, 59, 140, 0.5);
        &:last-child {
          margin-right: 0;
        }
      }
      .row-line {
        width: 1px;
        height: 40px;
        background: #0e5fff;
        border-radius: 0.5px;
        position: absolute;
        display: inline-block;
        user-select: none;
        > div {
          position: absolute;
          transform: translateX(-50%);
          left: 0;
          width: 7px;
          height: 1px;
          background: #0e5fff;
          border-radius: 0.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            opacity: 0.6;
            font-family: PingFangSC-Regular;
            font-weight: Regular;
            font-size: 12px;
            color: #ffffff;
            line-height: 17px;
          }
        }
        &-left {
          left: 14px;
        }
        &-center {
          left: 50%;
          // left: 240px;
        }
        &-right {
          right: 14px;
        }
      }
    }
    .row-1 {
      .row-line {
        bottom: 0;
        > div {
          content: '';
          top: 0;
          > span {
            margin-top: -22px;
          }
        }
      }
    }
    .row-2 {
      margin-top: 30px;
      .row-line {
        top: 0;
        > div {
          content: '';
          bottom: 0;
          > span {
            margin-bottom: -22px;
          }
        }
      }
    }
  }
</style>
